<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="orders__nav-list">
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 1}"
                @click="filterOrders(1)"
              >
                Recent
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 0}"
                @click="filterOrders(0)"
              >
                Pending
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 3}"
                @click="filterOrders(3)"
              >
                Declined
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 2}"
                @click="filterOrders(2)"
              >
                Paid
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-main">
        <div class="container">
          <Order v-for="order of orders" :key="order.id" :order="order" @click.native="openOrder(order)" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Order from './Order'
import moment from 'moment'
export default {
  name: 'AdminOrders',
  components: {Order, InfiniteLoading},
  data() {
    return {
      isLoading: false,
      status: 1,
      moment,
      rangeDate: this.getMonth()
    }
  },
  computed: {
    orders() {
      return this.$store.getters['orders/orders']
    },
    hasMoreResults() {
      return this.orders.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['orders/pagination'].total
    },
    page() {
      return this.$store.getters['orders/page']
    }
  },
  async created() {
    this.layout = this.$route.meta.layout
    if (!this.orders.length)
      await this.$store.dispatch('orders/fetchAdminOrders', {'filters[status]': [0, 2, 7], ...this.rangeDate})
  },
  methods: {
    async infLoad() {
      this.isLoading = true
      try {
        if (this.status === 1) {
          await this.$store.dispatch('orders/fetchAdminOrders', {'filters[status]': [0, 2, 7], ...this.rangeDate})
        } else {
          await this.$store.dispatch('orders/fetchAdminOrders', {'filters[status][]': this.status})
        }
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    async filterOrders(status) {
      this.status = status
      this.$store.commit('orders/reset')
      if (status === 1) {
        await this.$store.dispatch('orders/fetchAdminOrders', {'filters[status]': [0, 2, 7], ...this.rangeDate})
      } else {
        await this.$store.dispatch('orders/fetchAdminOrders', {'filters[status][]': status})
      }
    },
    openOrder(order) {
      this.$store.commit('orders/setOrder', order)
      this.$router.push('/orders/' + order.id)
    },
    getMonth() {
      const monthStart = moment()
        .startOf('month')
        .format('DD-MM-YYYY')

      const monthEnd = moment()
        .endOf('month')
        .format('DD-MM-YYYY')

      const range = {
        'filters[routeDate][from]': monthStart,
        'filters[routeDate][till]': monthEnd
      }
      return range
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
