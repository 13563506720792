var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order__wrapper" }, [
    _c("div", { staticClass: "order__header" }, [
      _c("div", { staticClass: "order__header-left" }, [
        _c("div", { staticClass: "order__status" }, [
          _c(
            "div",
            {
              staticClass: "indicator indicator--fill",
              class: {
                "indicator--yellow": _vm.order.status === 0,
                "indicator--green":
                  _vm.order.status === 1 ||
                  _vm.order.status === 2 ||
                  _vm.order.status === 7,
                "indicator--red":
                  _vm.order.status === 3 || _vm.order.status === 4,
                "indicator--orange":
                  _vm.order.status === 5 || _vm.order.status === 6
              }
            },
            [
              _vm.order.status === 0
                ? _c("span", [_vm._v("Pending")])
                : _vm._e(),
              _vm.order.status === 1
                ? _c("span", [_vm._v("Approved")])
                : _vm._e(),
              _vm.order.status === 2 ? _c("span", [_vm._v("Paid")]) : _vm._e(),
              _vm.order.status === 3
                ? _c("span", [_vm._v("Declined")])
                : _vm._e(),
              _vm.order.status === 4
                ? _c("span", [_vm._v("Payment error")])
                : _vm._e(),
              _vm.order.status === 5
                ? _c("span", [_vm._v("Waiting confirm")])
                : _vm._e(),
              _vm.order.status === 6
                ? _c("span", [_vm._v("Waiting for payment")])
                : _vm._e(),
              _vm.order.status === 7
                ? _c("span", [_vm._v("Created")])
                : _vm._e()
            ]
          )
        ]),
        _c("i", { staticClass: "ri-calendar-fill" }),
        _c("div", { staticClass: "order__date" }, [
          _vm._v(_vm._s(_vm.moment.utc(_vm.order.routeDate).format("DD MMM")))
        ])
      ]),
      _c("div", { staticClass: "order__header-right" }, [
        _vm.order.status === 0
          ? _c(
              "div",
              {
                staticClass: "order__header-buttons",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "order__button order__button--green",
                    on: {
                      click: function($event) {
                        return _vm.approve(_vm.order.id)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-checkbox-circle-fill" }),
                    _c("span", [_vm._v("Approve")])
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "order__button order__button--red",
                    on: {
                      click: function($event) {
                        return _vm.decline(_vm.order.id)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-close-circle-fill" }),
                    _c("span", [_vm._v("Decline")])
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "order__main" }, [
      _c("div", { staticClass: "order__customer" }, [
        _vm._m(0),
        _c("div", { staticClass: "order__customer-name" }, [
          _vm._v(_vm._s(_vm.$dots(_vm.order.pickUpName, _vm.amountCharset)))
        ]),
        _c("div", { staticClass: "order__customer-address" }, [
          _vm._v(_vm._s(_vm.$dots(_vm.order.pickUpAddress, _vm.amountCharset)))
        ])
      ]),
      _c("div", { staticClass: "order__customer" }, [
        _vm._m(1),
        _c("div", { staticClass: "order__customer-name" }, [
          _vm._v(_vm._s(_vm.$dots(_vm.order.dropOffName, _vm.amountCharset)))
        ]),
        _c("div", { staticClass: "order__customer-address" }, [
          _vm._v(_vm._s(_vm.$dots(_vm.order.dropOffAddress, _vm.amountCharset)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }