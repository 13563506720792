<template>
  <div class="order__wrapper">
    <div class="order__header">
      <div class="order__header-left">
        <div class="order__status">
          <div
            class="indicator indicator--fill"
            :class="{
              'indicator--yellow': order.status === 0,
              'indicator--green': order.status === 1 || order.status === 2 || order.status === 7,
              'indicator--red': order.status === 3 || order.status === 4,
              'indicator--orange': order.status === 5 || order.status === 6
            }"
          >
            <span v-if="order.status === 0">Pending</span>
            <span v-if="order.status === 1">Approved</span>
            <span v-if="order.status === 2">Paid</span>
            <span v-if="order.status === 3">Declined</span>
            <span v-if="order.status === 4">Payment error</span>
            <span v-if="order.status === 5">Waiting confirm</span>
            <span v-if="order.status === 6">Waiting for payment</span>
            <span v-if="order.status === 7">Created</span>
          </div>
        </div>
        <i class="ri-calendar-fill"></i>
        <div class="order__date">{{ moment.utc(order.routeDate).format('DD MMM') }}</div>
      </div>
      <div class="order__header-right">
        <div v-if="order.status === 0" class="order__header-buttons" @click.stop>
          <button class="order__button order__button--green" @click="approve(order.id)">
            <i class="ri-checkbox-circle-fill"></i>
            <span>Approve</span>
          </button>
          <button class="order__button order__button--red" @click="decline(order.id)">
            <i class="ri-close-circle-fill"></i>
            <span>Decline</span>
          </button>
        </div>
      </div>
    </div>
    <div class="order__main">
      <div class="order__customer">
        <div class="icon-button icon-button--round icon-button--blue">
          <i class="ri-arrow-up-circle-line"></i>
        </div>
        <div class="order__customer-name">{{ $dots(order.pickUpName, amountCharset) }}</div>
        <div class="order__customer-address">{{ $dots(order.pickUpAddress, amountCharset) }}</div>
      </div>
      <div class="order__customer">
        <div class="icon-button icon-button--round icon-button--orange">
          <i class="ri-arrow-down-circle-line"></i>
        </div>
        <div class="order__customer-name">{{ $dots(order.dropOffName, amountCharset) }}</div>
        <div class="order__customer-address">{{ $dots(order.dropOffAddress, amountCharset) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  name: 'ClientOrder',
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment,
      amountCharset: 200
    }
  },
  computed: {
    ...mapGetters({
      status: 'orders/statuses'
    })
  },
  created() {
    const fablet = document.documentElement.clientWidth <= 768
    if (fablet) this.amountCharset = 40
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchAdminOrders',
      approveOrder: 'orders/approve',
      declineOrder: 'orders/decline'
    }),
    ...mapMutations({
      update: 'orders/updateOrder',
      reset: 'orders/reset'
    }),
    async approve(id) {
      if (this.statusAprove) return
      const req = await this.approveOrder(id)
      if (req.data.result) {
        this.statusAprove = true
        this.reset()
        this.fetchOrders()
      }
    },
    async decline(id) {
      if (this.statusDecline) return
      const req = await this.declineOrder(id)
      if (req.data.result) {
        this.statusDecline = true
        this.reset()
        this.fetchOrders()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
