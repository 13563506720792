var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _c("div", { staticClass: "page-inner" }, [
        _c("div", { staticClass: "page-title-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-title-inner" }, [
              _c("div", { staticClass: "orders__nav-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "orders__nav-item",
                    class: { "orders__nav-item--active": _vm.status === 1 },
                    on: {
                      click: function($event) {
                        return _vm.filterOrders(1)
                      }
                    }
                  },
                  [_vm._v(" Recent ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "orders__nav-item",
                    class: { "orders__nav-item--active": _vm.status === 0 },
                    on: {
                      click: function($event) {
                        return _vm.filterOrders(0)
                      }
                    }
                  },
                  [_vm._v(" Pending ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "orders__nav-item",
                    class: { "orders__nav-item--active": _vm.status === 3 },
                    on: {
                      click: function($event) {
                        return _vm.filterOrders(3)
                      }
                    }
                  },
                  [_vm._v(" Declined ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "orders__nav-item",
                    class: { "orders__nav-item--active": _vm.status === 2 },
                    on: {
                      click: function($event) {
                        return _vm.filterOrders(2)
                      }
                    }
                  },
                  [_vm._v(" Paid ")]
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "page-main" }, [
          _c(
            "div",
            { staticClass: "container" },
            _vm._l(_vm.orders, function(order) {
              return _c("Order", {
                key: order.id,
                attrs: { order: order },
                nativeOn: {
                  click: function($event) {
                    return _vm.openOrder(order)
                  }
                }
              })
            }),
            1
          )
        ])
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }